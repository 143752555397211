<template>
  <Loader />
</template>

<script>
import Loader from '@/components/Loader'

export default {
  name: 'MagicLink',

  components: {
    Loader,
  },

  data() {
    return {
      link: null,
    }
  },
  computed: {},
  mounted() {
    this.link = this.$route.params.link

    this.check()
  },
  methods: {
    async check() {
      const checkResult = await this.$api.magicLink
        .check(this.link)
        .then((d) => d.data)
        .catch((err) => err)

      if (checkResult?.status == 400) {
        const { data: errorMessages } = checkResult?.errors

        this.$notify({
          group: 'message',
          type: 'error',
          duration: 4500,
          speed: 500,
          title: 'Error',
          text: errorMessages.length > 0 ? errorMessages[0] : '',
        })

        return this.$router.push({ name: this.$ROUTER.NAME.ACCOUNT.PANEL })
      }

      this.executeLink()
    },
    executeLink() {
      this.$api.magicLink
        .execute(this.link)
        .then(() => {
          this.$notify({
            group: 'message',
            type: 'success',
            duration: 4500,
            speed: 500,
            title: 'Link Applied Successfully!',
          })

          this.$router.push({ name: this.$ROUTER.NAME.ACCOUNT.PANEL })
        })
        .catch((err) => {
          const { data: errorMessages } = err.errors

          this.$notify({
            group: 'message',
            type: 'error',
            duration: 4500,
            speed: 500,
            title: 'Error',
            text: errorMessages.length > 0 ? errorMessages[0] : '',
          })

          return this.$router.push({ name: this.$ROUTER.NAME.ACCOUNT.PANEL })
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import './index';
</style>
